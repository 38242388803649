var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2"},[_c('table',{staticClass:"table table-striped table-bordered table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.tasks),function(task){return _c('tr',{key:task.id,staticClass:"odd gradeX pa-2 wrapper pointer",class:{
            redlist: task.status == 1, 
            purplelist: task.status == 2, 
            greenlist: task.status == 3, 
            yellowlist: task.status == 4,
            blacklist: task.status == 5,
            orangelist: task.status == 6 || task.status == 8,
            },on:{"click":function($event){return _vm.startChat(task)}}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(task.code))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(task.topic))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(task.unit))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("diffForHumans")(task.expiry_time)))]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(task.full_pay + " KES")+" ")]),_c('br'),_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("full_pay")(task))+" ")]),_c('br'),_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("mode_of_payment")(task))+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("status")(task.status)))]),_c('td',{staticClass:"text-center"},[(task.bids)?_c('span',[_vm._v(" "+_vm._s(task.bids.length + " Bids")+" "),_c('br')]):_vm._e(),(task.offers)?_c('span',[_vm._v(" "+_vm._s(task.offers.length + " Offers")+" "),_c('br')]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm._f("unread_message")(task))+" ")])])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"grey white--text"},[_c('th',[_vm._v("Task Code")]),_c('th',[_vm._v("Topic")]),_c('th',[_vm._v("Unit ")]),_c('th',[_vm._v("Deadline")]),_c('th',[_vm._v("Payment")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Info")])])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 wrapper pointer",class:{
        redlist: _vm.task.status == 1, 
        purplelist: _vm.task.status == 2, 
        greenlist: _vm.task.status == 3, 
        yellowlist: _vm.task.status == 4,
        blacklist: _vm.task.status == 5,
        orangelist: _vm.task.status == 6 || _vm.task.status == 8,
      },on:{"click":_vm.startChat}},[(!_vm.deleting)?_c('section',[_c('span',{staticClass:"head"},[_vm._v(" "+_vm._s(_vm.task.code)+" "+_vm._s(': ')+" "+_vm._s(_vm.task.topic)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.task.unit)+" "+_vm._s(_vm.task.type)+" "+_vm._s(_vm.posted_time)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.full_pay)+_vm._s(_vm.mode_of_payment)+" "),_c('br'),(_vm.task.difficulty)?_c('span',{class:{
          'purple--text': _vm.task.status == 2, 
          'green--text': _vm.task.status == 3, 
          'yellow--text': _vm.task.status == 4,
          'black--text': _vm.task.status == 5,
          'orange--text': _vm.task.status == 6 || _vm.task.status == 8,
        }},[_vm._v(" "+_vm._s(_vm.status + " ||")+" ")]):_vm._e(),(!_vm.task.difficulty)?_c('span',{staticClass:"red--text"},[_c('v-icon',{staticClass:"red--text",attrs:{"small":""}},[_vm._v(" mdi-alert ")]),_vm._v(" "+_vm._s("task upload incomplete!")+" ")],1):_vm._e(),((_vm.task.status < 2) && (_vm.task.offers.length > 0))?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s((_vm.task.offers.length > 1) ? _vm.task.offers.length + " offers" : _vm.task.offers.length + " offer")+" ")]):_vm._e(),(_vm.unread_offer_message)?_c('v-icon',{staticClass:"ml-1 green--text",attrs:{"small":""}},[_vm._v(" mdi-message ")]):_vm._e(),((_vm.task.status < 2) && (_vm.task.bids.length > 0))?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s((_vm.task.bids.length > 1) ? _vm.task.bids.length + " bids" : _vm.task.bids.length + " bid")+" ")]):_vm._e(),(_vm.unread_bid_message)?_c('v-icon',{staticClass:"ml-1 green--text",attrs:{"small":""}},[_vm._v(" mdi-message ")]):_vm._e(),((_vm.task.writer))?_c('span',{staticClass:"blue--text "},[_vm._v(" "+_vm._s(_vm.task.writer.user.username)+" ")]):_vm._e()],1):_c('section',[_c('div',{staticClass:"center",staticStyle:{"margin-top":"50px"}}),_c('v-row',{staticClass:"no-gutters"},[_c('v-col',{staticClass:"col-4 offset-4"},[_c('v-progress-linear',{attrs:{"indeterminate":"","rounded":"","color":"blue darken-2","height":"5"}})],1)],1),_c('v-row',{staticClass:"no-gutters"},[_c('v-col',{staticClass:"col-12 blue--text d-flex justify-center darken-2"},[_vm._v(" deleting task ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }